import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Clock, MapPin, Share2, ArrowLeft } from "lucide-react";
import { RootState, AppDispatch } from '@/actions/store'; // Adjust the import path as necessary
import { fetchJobDetails } from '../../actions/jobActions'; // Adjust the import path as necessary

// Custom Hook to detect mobile or desktop view
const useMediaQuery = (width: number) => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < width);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < width);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return isMobile;
};

const JobDetails: React.FC = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const isMobile = useMediaQuery(768); // Adjust the width for mobile breakpoint
  const { job, loading, error } = useSelector((state: RootState) => state.job);

  useEffect(() => {
    if (jobId && (!job || job.id !== jobId)) {
      dispatch(fetchJobDetails(jobId));
    }
  }, [dispatch, jobId, job]);

  const handleShare = () => {
    if (job && navigator.share) {
      navigator.share({
        title: job.jobTitle,
        text: `Check out this job opportunity at ${job.companyName}: ${job.jobTitle}`,
        url: window.location.href,
      }).catch((error) => console.error('Error sharing:', error));
    } else {
      console.error('Sharing not supported or job data not available');
      alert('Sharing is not supported in your browser.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!job) return <div>Job not found</div>;

  // Separate components based on the screen size
  return isMobile ? <JobDetailsMobile job={job} handleShare={handleShare} /> : <JobDetailsDesktop job={job} handleShare={handleShare} />;
};

// Desktop component
const JobDetailsDesktop: React.FC<{ job: any, handleShare: () => void }> = ({ job, handleShare }) => (
  <div className="rounded-md p-6 bg-white relative m-20">
    <div className="absolute top-4 right-4 flex flex-col space-y-2">
      <div className="flex space-x-4 font-bold">
        <button
          onClick={handleShare}
          className="flex items-center text-[#344054] px-6 py-3 rounded-md border-2 border-[#D0D5DD] hover:bg-[#f4f3ff] transition"
        >
          <Share2 className="w-4 h-4" />
          <span className="ml-2">Share</span>
        </button>
        <a
          href={job.applyLink}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center bg-[#5925DC] text-white px-6 py-3 rounded-md hover:bg-purple-700 transition"
        >
          Apply Now
        </a>
      </div>
      <div className="text-xm text-[#7F70ED] text-600">{`Posted on ${new Date(job.jobTitle).toLocaleDateString()}`}</div>
    </div>

    {/* Company and Job Details */}
    <div className="flex items-center mb-6">
      <img src={job.companyLogo} alt={`${job.companyName} logo`} className="w-32 h-32 mr-4" />
      <div>
        <span className="text-sm text-[#5925DC] lg:py-1 px-2.5 rounded-xl bg-[#F4F3FF] ml-2">{job.companyName}</span>
        <h1 className="text-5xl text-[#101828] font-bold p-2">{job.jobTitle}</h1>
        <div className="flex items-center space-x-4 text-sm text-gray-500 ml-2">
          <span className="flex items-center">
            <MapPin className="mr-1 text-slate-400" />
            {job.location}
          </span>
          <span className="flex items-center">
            <Clock className="mr-1 text-slate-400" />
            {job.employment}
          </span>
        </div>
      </div>
    </div>

    <div className="mb-6">
      <h2 className="text-lg font-semibold mb-2">Role Overview</h2>
      <div className="text-gray-700" dangerouslySetInnerHTML={{ __html: job.jobDescription }} />
    </div>

    {/* Bottom Buttons */}
    <div className="flex justify-center space-x-4 mt-4">
      <button onClick={handleShare} className="flex items-center text-[#344054] px-6 py-3 rounded-md border-2 font-bold border-[#D0D5DD] hover:bg-[#f4f3ff] transition">
        <Share2 className="w-4 h-4" />
        <span className="ml-2 hidden md:block">Share</span>
      </button>
      <a href={job.applyLink} target="_blank" rel="noopener noreferrer" className="flex items-center bg-[#5925DC] text-white font-bold px-6 py-3 rounded-md hover:bg-purple-700 transition">
        Apply Now
      </a>
    </div>

    <div className="flex mt-4 text-center justify-center">
      <ArrowLeft className="text-[#6941C6]" />
      <a href="/job-updates" className="text-[#6941C6] font-bold hover:underline ml-2">View All Jobs</a>
    </div>
  </div>
);

const JobDetailsMobile: React.FC<{ job: any, handleShare: () => void }> = ({ job, handleShare }) => (
  <div className="p-4 mt-20">

    {/* Job details */}
    <div className="flex items-center mb-4">
      {/* Logo on the left */}
      <img src={job.companyLogo} alt={`${job.companyName} logo`} className="w-16 h-16 mr-4" />

      {/* Job Title and Company Details on the right */}
      <div className="flex flex-col items-start">
        {/* Post Date */}
        <div className="text-sm text-[#7F70ED] mb-1">{`Posted on ${new Date(job.postedDate).toLocaleDateString(undefined, { day: '2-digit', month: 'short', year: 'numeric' })}`}</div>

        {/* Job Title */}
        <h1 className="text-3xl font-bold mb-1">{job.jobTitle}</h1>

        {/* Company Name */}
        <span className="text-sm text-[#6941C6] py-1 px-3 rounded-xl bg-[#F4F3FF] mb-2 inline-block whitespace-nowrap">{job.companyName}</span>

        {/* Job Details */}
        <div className="flex space-x-4 text-sm text-gray-500">
          <span className="flex items-center">
            <Clock className="mr-1 text-slate-400" />
            {job.employment}
          </span>
          <span className="flex items-center">
            <MapPin className="mr-1 text-slate-400" />
            {job.location}
          </span>
          <span className="flex items-center">
            <span className="mr-1 text-slate-400">👥</span>
            {job.years}
          </span>
        </div>
      </div>
    </div>

    {/* Apply and Share buttons */}
    <div className="flex justify-between mb-4">
      <a href={job.applyLink} target="_blank" rel="noopener noreferrer" className="bg-[#5925DC] text-white w-full text-center px-4 py-2 rounded-md hover:bg-purple-700 transition">
        Apply Now
      </a>
      <button onClick={handleShare} className="ml-2 flex items-center justify-center p-2 text-[#344054] border-2 border-[#D0D5DD] rounded-md hover:bg-[#f4f3ff] transition">
        <Share2 className="w-4 h-4" />
      </button>
    </div>

    {/* Role Overview */}
    <div className="mb-4">
      <h2 className="text-lg font-semibold mb-2">Role Overview</h2>
      <div className="text-gray-700" dangerouslySetInnerHTML={{ __html: job.jobDescription }} />
    </div>

    {/* Back to Job List */}
    <div className="flex justify-center mt-4">
      <ArrowLeft className="text-[#6941C6]" />
      <a href="/job-updates" className="text-[#6941C6] font-bold hover:underline ml-2">View All Jobs</a>
    </div>
  </div>
);


export default JobDetails;
