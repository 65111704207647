import React from 'react';
import { Clock, MapPin, User, Edit, Trash2 } from "lucide-react";

interface Job {
    id: string;
    date: string;
    companyLogo: string;
    companyName: string;
    jobTitle: string;
    jobDescription: string;
    location: string;
    employment: string;
    applyLink: string;
}

interface JobCardProps {
    job: Job;
    onDelete: (job: Job) => void;
    onEdit: (job: Job) => void;
}

const AdminJobCard: React.FC<JobCardProps> = ({ job, onDelete, onEdit }) => {
    return (
        <div className="relative p-4 border rounded-lg flex flex-col md:flex-row items-start md:items-center">
            <div className="absolute top-2 right-4 flex space-x-2">
                <button
                    onClick={() => onDelete(job)}
                    className="flex items-center bg-red-100 text-red-600 py-2 px-3 rounded-lg"
                >
                    <Trash2 className="w-4 h-4" />
                    <span className="ml-1">Delete</span>
                </button>
                <button
                    onClick={() => onEdit(job)}
                    className="flex items-center bg-purple-100 text-purple-600 py-2 px-3 rounded-lg"
                >
                    <Edit className="w-4 h-4" />
                    <span className="ml-1">Edit</span>
                </button>
            </div>
            <div className="flex flex-col md:flex-row items-start md:items-center space-x-4 w-full mt-16 md:mt-0">
                <div className="w-16 h-16 md:w-[87px] md:h-[87px] flex-shrink-0 flex items-center mb-4 md:mb-0">
                    <img src={job.companyLogo} alt="company logo" className="w-full h-full object-contain" />
                </div>
                <div className="flex-1">
                    <h2 className="text-lg md:text-xl font-bold">{job.jobTitle}</h2>
                    <p className="text-purple-500">{job.companyName}</p>
                        <div
                            className="text-sm text-[#475467] mt-2 overflow-hidden"
                            style={{
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            maxHeight: '3em', // This can help limit the height of the element
                            }}
                            dangerouslySetInnerHTML={{ __html: job.jobDescription }}
                        />                    
                    <div className="flex flex-col md:flex-row md:space-x-2 mt-2 text-sm md:text-base text-gray-500">
                        <span className="flex items-center mb-2 md:mb-0">
                            <MapPin className="w-4 h-4 text-slate-400 mr-1" /> {job.location}
                        </span>
                        <span className="flex items-center mb-2 md:mb-0">
                            <Clock className="w-4 h-4 text-slate-400 mr-1" /> {job.employment}
                        </span>
                        <span className="flex items-center">
                            <User className="w-4 h-4 text-slate-400 mr-1" /> 2024 & 2025
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminJobCard;
