import React from 'react'

const Logos =['N','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T']

const CRTPlaced = () => {
  return (
    <div className='bg-[#53389E] text-white py-20'>
        <div className='text-center flex flex-col gap-4 md:w-1/2 mx-auto px-10 md:px-0'>
            <span className='font-semibold'>Achievement</span>
            <h1 className='font-semibold text-4xl'>Our students placed at</h1>
            <p className='font-normal text-[#E9D7FE] text-xl'>Connect your tools, connect your teams. With over 200 apps already available in our directory, your team’s favourite tools are just a click away.</p>
        </div>
        <div className=' mx-auto w-3/4 h-fit mt-4 p-2 '>
          <div className='w-full  h-full grid grid-cols-3 md:grid-cols-5 lg:grid-cols-7 gap-5 justify-items-center place-content-center'>
            {Logos.map((logo,idx) => (
              <div key={idx}>
                <div className='h-8 w-8 flex justify-center items-center bg-[#7F56D9] rounded-full'>
                  {logo}
                </div>
              </div>

            ))}
          </div>
        </div>
    </div>
  )
}

export default CRTPlaced