import { Logos } from '../../data/Logos';


interface FeatureItemProps {
  Icon: React.ElementType;
  title: string;
  description: string;
}

interface PricingCardProps {
  Icon: React.ElementType;
  title: string;
  planDescription: string;
  features: string[];
  buttonText: string;
}


const FeatureItem: React.FC<FeatureItemProps> = ({ Icon, title, description }) => (
  <div className='flex gap-3 my-12'>
    <div><Icon /></div>
    <div>
      <h1 className='text-headingBlack font-semibold text-xl '>{title}</h1>
      <p className='text-base font-normal text-paraGray my-2'>{description}</p>
    </div>
  </div>
);


const PricingCard: React.FC<PricingCardProps> = ({ Icon, title, planDescription, features, buttonText }) => (
  <div className='bg-[#f9f5ff] px-10 py-5 rounded-md text-center '>
    <div className='flex flex-col justify-center items-center gap-3 min-h-52 '>
      <Icon />
      <h1 className='text-themepruple font-semibold text-xl'>{title}</h1>
      <p className='text-themepruple font-normal text-base'>{planDescription}</p>
    </div>
    <div className='flex flex-col gap-8 py-8 max-h-64  '>
      {features.map((feature, index) => (
        <span key={index} className='flex gap-2'>
          <Logos.CheckIcon />
          <span className='text-themepruple font-normal text-base text-start'>{feature}</span>
        </span>
      ))}
    </div>
    <button className='text-white bg-themepruple w-full py-2 rounded-md'>{buttonText}</button>
  </div>
);
const featuresList: FeatureItemProps[] = [
    {
      Icon: Logos.ChatBubleIcon,
      title: 'Programming Language Proficiency',
      description: 'Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop.',
    },
    {
      Icon: Logos.ThunderBoltIcon,
      title: '100+ Hours of Live Training',
      description: 'An all-in-one customer service platform that helps you balance everything your customers need to be happy.',
    },
    {
      Icon: Logos.ChartBreakoutSquare,
      title: 'In-College Training Delivery',
      description: 'Measure what matters with Untitled’s easy-to-use reports. You can filter, export, and drill down on the data in a couple clicks.',
    },
  ];

  const pricingPlans: PricingCardProps[] = [
    {
      Icon: Logos.ThunderBoltIcon,
      title: 'Aptitude & Reasoning Training',
      planDescription: 'Our most popular plan',
      features: [
        '200+ Integrations',
        'Advanced Reporting and Analytics',
        'Up to 20 Individual Users',
        'Email Support',
      ],
      buttonText: 'Get Syllabus',
    },
    {
      Icon: Logos.ThunderBoltIcon,
      title: 'Verbal',
      planDescription: 'Our best plan',
      features: [
        '100+ Applications',
        'Most Useful Topics',
        'Up to 20 Individual Users',
        'Email Support',
        'Great learning'
      ],
      buttonText: 'Get Started',
    },
  ];

const CRTPricing: React.FC = () => {

 

  return (
    <div className='lg:px-20 md:px-14 px-7'>
      <div className='px-1'>
        <span className='text-[#6941C6] text-base font-semibold'>KampKode offers - to upgrade your students</span>
        <h2 className='text-headingBlack font-semibold text-4xl my-3'>Pricing as simple as our cards</h2>
        <p className='text-paraGray text-lg'>Simple, transparent pricing that grows with you. Try any plan free for 30 days.</p>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-3 my-6 md:my-12 py-3 px-5 gap-5'>
        <div>
          {featuresList.map((feature, index) => (
            <FeatureItem key={index} {...feature} />
          ))}
        </div>
        {pricingPlans.map((plan, index) => (
          <PricingCard key={index} {...plan} />
        ))}
      </div>
    </div>
  );
};

export default CRTPricing;
