import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useDispatch } from "react-redux";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";

import Layout from "./Layout";
import HomePage from "./HomePage";
import LayoutWithoutFooter from "./LayoutWithoutFooter";
import Editor from "./Editor";
import FlashcardDetailsPage from "./Flashcards/FlashcardsDetailsPage";
import About from "./AboutUs/About";
import ContactUs from "./Contact/ContactUs";
import Quizzes from "./Quizzes/Quizzes";
import Quiz from "./Quizzes/Quiz";
import Flashcards from "./Flashcards/Flashcards";
import LiveClasses from "./LiveClasses/LiveClasses";
import { setCurrentUser } from "../actions/actions";
import InternshipsPage from "./InternshipsSection/IntershipsPage"
import ReadingHome from "./ReadingMaterials/ReadingHome";
import ReadingMaterial from "./ReadingMaterials/Reading";
import OurServices from "./OurServices";
import MyDashboard from "./MyDashboard";
import SkillAssignments from "./Skill Assignments/SkillAssignments";
import SkillAssignment from "./Skill Assignments/SkillAssigment";
import CodeEditor from "./LiveClasses/CodeEditor";
import Class from "./LiveClasses/Class";
import { SocketProvider } from "./LiveClasses/SocketProvider";
// import Editor from "./Editor";
import MonacoEditor from "./LiveClasses/MonacoEditor";
import CertificateVerification from "./Certificate/CertificateVerification";
import ScrollToTop from "./utils/ScrollToTop";
import ProjectsPage from "./ProjectSection/Projects/ProjectsPage";
import ProjectDetail from "./ProjectSection/ProjectDetails/ProjectDetail";
import JobUpdates from "./JobUpdates/JobUpdates";
import JobDetails from "./JobUpdates/JobDetails";
import ProtectedAdminRoute from "./ProtectedAdminRoute";
import NoAccess from "./NoAccess"; 
import CertificateForm from './Certificate/CertificateForm';
import InternshipApplyForm from "./InternshipsSection/ExploreInternships/InternshipApplicationForm";
import useDisableShortcuts from '../hooks/useDisableShortcuts';
import PrivacyPolicy from "./PrivacyPolicy";
import CRTPage from "./CRT/CRTPage";

// Move the creation of the store to a higher scope
const store = createStore(rootReducer, compose(applyMiddleware(thunk)));
const auth = getAuth();

const App = (props: any) => {
  //to disable shortcuts and right click in production env
  useDisableShortcuts();
  const dispatch = useDispatch();

  React.useEffect(() => {
    onAuthStateChanged(auth, async (user: any) => {
      if (user) {
        dispatch(setCurrentUser(user));
      }
    });
  }, [dispatch]);

  const logout = (callBack: () => {}) => {
    return signOut(auth)
      .then(() => {
        callBack();
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <BrowserRouter>
      <SocketProvider>
      <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Layout {...props} logout={logout} />}>
            <Route index element={<HomePage />} />
          </Route>
          {/* below routes are without footer */}
          <Route element={<LayoutWithoutFooter {...props} logout={logout} />}>
            <Route path="/editor" element={<Editor />} />
            <Route path="/about-us" element={<About />} />
            <Route path='/projects' element={<ProjectsPage/>} />
            <Route path='/projects/:category/:projectName' element={<ProjectDetail/>} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="/quizzes" element={<Quizzes />} />
            <Route path="flashcards" element={<Flashcards />} />
            <Route path="/internships" element={<InternshipsPage />} />
            <Route path="/live-classes" element={<LiveClasses />} />
            <Route path="our-services" element={<OurServices />} />
            <Route path="reading-materials" element={<ReadingHome />} />
            <Route path="dashboard" element={<MyDashboard />} />
            <Route path="skill-assignments" element={<SkillAssignments />} />
            <Route path="/certificate/verify" element={<CertificateVerification />} />
            <Route path="/job-updates" element={<JobUpdates />} />
            <Route path="/job-updates/:jobId" element={<JobDetails />} />
            <Route path="/job-updates/admin" element={<ProtectedAdminRoute />} />
            <Route path="/no-access" element={<NoAccess />} />
            <Route path="/certificate-generator" element={<CertificateForm/>}/>
            <Route path="/internship/apply" element={<InternshipApplyForm/>}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/crt" element={<CRTPage />} />
          </Route>
          <Route path="/quizzes/:id" element={<Quiz />} />
          <Route
            path="/skill-assignment/:skillTitle"
            element={<SkillAssignment />}
          />
          <Route path="/code-editor" element={<CodeEditor {...props} />} />
          <Route path="/class" element={<Class />} />
          <Route path="/monaco-editor" element={<MonacoEditor />} />
          <Route path="/flashcards/:id" element={<FlashcardDetailsPage />} />
          <Route
            path="/reading-materials/:courseId"
            element={<ReadingMaterial />}
          />
        </Routes>
      </SocketProvider>
    </BrowserRouter>
  );
};

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

export default AppWrapper;
