import React from "react";
import Formimg from "./Form.webp";
import DotPattern from "../magicui/dot-pattern";
import { cn } from "../magicui/lib/utils";
const CRTDemoForm = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 px-5  md:px-16  lg:px-20 py-24 w-screen overflow-hidden">
      <div className=" hidden md:block">
        <img src={Formimg} alt="" />
      </div>
      <div className="px-2 h-full flex items-center  ">

        <div className="border shadow-lg rounded-md mx-auto p-6 w-96 relative bg-background h-fit ">
          <h1 className="text-3xl text-headingBlack font-medium w-72 mb-3">
            Schedule a Demo of our product
          </h1>
          <fieldset className="border px-3 rounded-md mb-4">
            <legend className="text-sm text-headingBlack font-semibold px-1">
              First Name
            </legend>
            <input
              type="text"
              placeholder="John"
              className="w-full p-1 text-sm focus:outline-none text-paraGray"
            />
          </fieldset>

          <fieldset className="border px-3 rounded-md mb-4">
            <legend className="text-sm text-headingBlack font-semibold px-1">
              Last Name
            </legend>
            <input
              type="text"
              placeholder="Doe"
              className="w-full p-1 text-sm focus:outline-none text-paraGray"
            />
          </fieldset>

          <fieldset className="border px-3 rounded-md mb-4">
            <legend className="text-sm text-headingBlack font-semibold px-1">
              Email Address
            </legend>
            <input
              type="email"
              placeholder="example@email.com"
              className="w-full p-1 text-sm focus:outline-none text-paraGray"
            />
          </fieldset>

          <fieldset className="border px-3 rounded-md mb-4">
            <legend className="text-sm text-headingBlack font-semibold px-1">
              Phone Number
            </legend>
            <input
              type="tel"
              placeholder="+0123456789"
              className="w-full p-1 text-sm focus:outline-none text-paraGray"
            />
          </fieldset>

          <button className=" bg-yellow-400 text-headingBlack font-bold text-lg rounded-md px-4 py-2 w-full transition-colors duration-300 tracking-wide">
            Submit
          </button>

        <DotPattern
          className={cn(
            "absolute  -left-10 -top-10 h-20 w-24 -z-[1]"
          )}
          cx={5}
          cy={5}
          cr={3}
          />
        <DotPattern
          className={cn(
            "absolute left-80 top-96 h-24 w-24 -z-[1]"
          )}
          cx={5}
          cy={5}
          cr={3}
          />
          </div>
      </div>

    </div>
  );
};

export default CRTDemoForm;
