import React from 'react'
import banner from './Image.webp'
const CRTIntro = () => {
  return (
    <div className='relative'>
        <div className='bg-[#53389E] w-screen  text-center h-fit py-3 '>
        <div className=' w-full md:w-3/4 mx-auto text-white py-12 md:py-24 px-7 md:px-14 lg:px-20'>
            <p>Design</p>
          <h1 className=' lg:text-5xl font-semibold md:text-3xl text-2xl md:leading-[5rem]'>
            What is campus recruitment training (CRT)?
          </h1>
          <p>
          Introduction to Wireframing and its Principles. Learn from the best in the industry with tips, tools, and best practices from those in the know.
          </p>
        </div>
        </div>
        <img src={banner} alt="Banner" className='-mt-10  md:-mt-20 mx-auto md:w-11/12 w-96 '/>

        <div className='w-full md:w-2/4 mx-auto py-12 md:my-24 px-10'>
            
            <p className='mb-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ullamcorper mattis lorem non. Ultrices praesent amet ipsum justo massa. Eu dolor aliquet risus gravida nunc at feugiat consequat purus. Non massa enim vitae duis mattis. Vel in ultricies vel fringilla.</p>

            
            <h1 className='text-3xl font-semibold border-t py-5'>Introduction</h1>
            <p className='mb-3'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatum cum asperiores dicta sapiente ex officia voluptatibus, omnis animi consequuntur tenetur sit, itaque ut quisquam architecto debitis, aliquam at ipsum nesciunt?</p>
            <p className='mb-3'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni maxime maiores iure placeat. Sed illo, necessitatibus sint nesciunt commodi, exercitationem aspernatur officiis quam excepturi totam numquam nobis beatae saepe illum.</p>
            <div className='h-48 bg-amber-200'>
            </div>
        </div>

    </div>
  )
}

export default CRTIntro
