import { CirclePlayIcon } from 'lucide-react'
import React from 'react'
import NumberTicker from '../magicui/number-ticker'

const CRTStatus = () => {
  return (
    <div className='px-7 md:px-14 lg:px-20'>
        <div className='flex flex-col md:flex-row justify-between  px-1 py-5'>
            <div>
                <h1 className='font-semibold text-4xl text-[#101828] pb-1'>Build something great</h1>
                <p className='text-[#475467]'> Everything you need to build modern UI and great products.</p>
            </div>
            <div className='flex flex-col md:flex-row gap-1 mt-2 md:mt-0'>
                <button className='px-3 gap-1 py-2 border rounded-md flex items-center justify-center h-fit'><CirclePlayIcon/> Demo</button>
                <button className='px-3 py-2 text-white bg-themepruple h-fit border rounded-md'>Get started</button>
            </div>
        </div>
        <div className='h-fit lg:h-60  flex flex-col lg:flex-row  text-themepruple items-center justify-center md:justify-between rounded-lg py-12 w-full  mx-auto gap-y-10 text-center md:text-start'>
          <div className='flex flex-col w-56  md:w-60'>
            <NumberTicker value={400}  className='text-themepruple font-semibold'/>
            <p className='text-[#42307D] text-xl font-medium  mt-4'>Projets completed</p>
            <p className='text-[#475467]'>We&apos;ve helped build over 400 amazing projects.</p>
          </div>
          <div className='flex flex-col  w-56  md:w-60 '>
            <NumberTicker value={600}  className='text-themepruple font-semibold'/>
            <p className='text-[#42307D] text-xl font-medium  mt-4'>Return on investment</p>
            <p className='text-[#475467]'>We&apos;ve helped build over 400 amazing projects.</p>
          </div>
          <div className='flex flex-col  w-56  md:w-60 '>
            <NumberTicker value={10000}  className='text-themepruple font-semibold'/>
            <p className='text-[#42307D] text-xl font-medium  mt-4'>Global downloads</p>
            <p className='text-[#475467]'>We&apos;ve helped build over 400 amazing projects.</p>
          </div>
          <div className='flex flex-col w-56  md:w-60 '>
            <NumberTicker value={200}  className='text-themepruple font-semibold'/>
            <p className='text-[#42307D] text-xl font-medium  mt-4'>5-star reviews</p>
            <p className='text-[#475467]'>We&apos;ve helped build over 400 amazing projects.</p>
          </div>
          

        </div>
        
    </div>
  )
}

export default CRTStatus