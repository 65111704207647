import { AppDispatch, RootState } from './store';
import axios from 'axios';
import { fetchJobsRequest, fetchJobsSuccess, fetchJobsFailure, fetchJobDetailsRequest, fetchJobDetailsSuccess, fetchJobDetailsFailure } from '../reducers/jobReducers';

interface Job {
  id: string;
  date: string;
  companyLogo: string;
  companyName: string;
  jobTitle: string;
  jobDescription: string;
  location: string;
  employment: string;
  applyLink: string;
}

interface JobApiResponse {
  status: number;
  data: Job; // The data property contains a Job object
}

const baseUrl = process.env.REACT_APP_ENV
  ? "https://asia-south1-kampkode-ecb6c.cloudfunctions.net/kampkode/"
  : "http://127.0.0.1:5001/kodekamp-dev-7f75c/asia-south1/kampkode/";

export const fetchJobs = (page: number) => {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        try {
            dispatch(fetchJobsRequest());

            const response = await axios.get<{ data: Job[] }>(
                `${baseUrl}jobs?page=${page}&limit=10`
            );

            const jobsData = response.data.data.map(job => ({
                id: job.id,
                date: job.date,
                companyLogo: job.companyLogo,
                companyName: job.companyName,
                jobTitle: job.jobTitle,
                jobDescription: job.jobDescription,
                location: job.location,
                employment: job.employment,
                applyLink: job.applyLink,
            }));

            dispatch(fetchJobsSuccess({ jobs: jobsData, hasMore: jobsData.length > 0 }));
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : "An unknown error occurred";
            dispatch(fetchJobsFailure(errorMessage));
        }
    };
};
// Delete Job Action
export const deleteJob = (jobId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      // Optionally, you can dispatch a request action to set loading state
      dispatch(fetchJobsRequest());

      // Send DELETE request to the server
      await axios.delete(`${baseUrl}jobs/${jobId}`);

      // Fetch the updated list of jobs after deletion
      dispatch(fetchJobs(1)); // Optionally reset to the first page or keep the current page logic
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(fetchJobsFailure(errorMessage));
    }
  };
};

// Edit Job Action
export const editJob = (jobId: string, updatedJobData: Partial<Job>) => {
  return async (dispatch: AppDispatch) => {
    try {
      // Optionally, you can dispatch a request action to set loading state
      dispatch(fetchJobsRequest());

      // Send PUT request to the server to update the job details
      await axios.put(`${baseUrl}jobs/${jobId}`, updatedJobData);
      // Fetch the updated list of jobs after editing
      dispatch(fetchJobs(1)); // Optionally reset to the first page or keep the current page logic
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "An unknown error occurred";
      dispatch(fetchJobsFailure(errorMessage));
    }
  };
};

export const fetchJobDetails = (jobId: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      // Dispatch a request action to set loading state
      dispatch(fetchJobDetailsRequest());

      // Attempt to fetch job details from the server
      const response = await axios.get<JobApiResponse>(`${baseUrl}jobs/${jobId}`);

      // Check if the response status is within the success range (200-299)
      if (response.status >= 200 && response.status < 300) {
        dispatch(fetchJobDetailsSuccess(response.data.data));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      let errorMessage = 'An unknown error occurred';

      if (axios.isAxiosError(error)) {
        // Handle Axios-specific errors
        if (error.response) {
          // Server responded with a status other than 2xx
          errorMessage = `Server error: ${error.response.status}`;
        } else if (error.request) {
          // Request was made, but no response received (network issue)
          errorMessage = 'Network error: No response received from the server';
        } else {
          // Something else happened in setting up the request
          errorMessage = `Request setup error: ${error.message}`;
        }
      } else if (error instanceof Error) {
        // Handle generic JavaScript errors
        errorMessage = error.message;
      }

      // Log the error for debugging purposes
      console.error('Error fetching job details:', errorMessage);

      // Dispatch failure action with error message
      dispatch(fetchJobDetailsFailure(errorMessage));
    }
  };
};