import { cn } from "../magicui/lib/utils";
import Marquee from "../magicui/marquee";

const companies : string[] = ['amazon', 'flipkart', 'google', 'apple', 'microsoft', 'facebook'];

const ReviewCard = ({ company }: { company: string }) => (
  <div
    className={cn(
      "relative w-36 cursor-pointer overflow-hidden rounded-xl border p-4 text-center",
      // Light styles
      "border-gray-950/[.1] bg-gray-950/[.01] hover:bg-gray-950/[.05]",
      // Dark styles
      "dark:border-gray-50/[.1] dark:bg-gray-50/[.10] dark:hover:bg-gray-50/[.15]"
    )}
  >
    <h1 className="capitalize">{company}</h1>
  </div>
);

const CRTHeroSlide = () => (
  <div className="relative flex h-fit w-full flex-col items-center justify-center overflow-hidden rounded-lg bg-background">
    <Marquee pauseOnHover className="[--duration:20s]">
      {companies.map((company, idx) => (
        <ReviewCard key={idx} company={company} />
      ))}
    </Marquee>
  </div>
);

export default CRTHeroSlide;
