import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchJobs } from '../../actions/jobActions';
import ClientJobCard from "./CleintJobCard";
import { isUserAdmin } from "../../uiHelper";
import { RootState, AppDispatch } from '@/actions/store';
import { useNavigate } from 'react-router-dom';
import { User } from "lucide-react";

interface Job {
  id: string;
  date: string;
  companyLogo: string;
  companyName: string;
  jobTitle: string;
  jobDescription: string;
  location: string;
  employment: string;
  applyLink: string;
}

const SearchJobs: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { jobs, loading, hasMore, page, fetchFailed } = useSelector((state: RootState) => state.job);
  const user = useSelector((state: any) => state.currentUserReducer);
  const [isTeacher, setIsTeacher] = useState(false);

  useEffect(() => {
    if (user && user.email) {
      setIsTeacher(isUserAdmin(user.email));
    }
  }, [user]);

  const fetchJobsIfNeeded = useCallback(() => {
    if (!loading && hasMore && !fetchFailed) {
      dispatch(fetchJobs(page));
    }
  }, [dispatch, page, loading, hasMore, fetchFailed]);

  useEffect(() => {
    fetchJobsIfNeeded();
  }, [fetchJobsIfNeeded]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop
      >= document.documentElement.offsetHeight - 50
    ) {
      fetchJobsIfNeeded();
    }
  }, [fetchJobsIfNeeded]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const handleClick = () => {
    navigate("/job-updates/admin");
  };

  return (
    <div className="relative flex flex-col justify-center items-center px-4">
      <div className="mt-8 w-full">
        <div className="text-center">
          <div className="text-2xl md:text-3xl font-semibold">
            Job Alerts & Opportunities
          </div>
          <div className="text-gray-600 text-base md:text-lg tracking-wide mt-2">
            Stay updated with the latest job openings and career opportunities.
            <br />
            Your next career move starts here!
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-6 items-end md:w-[1080px]">
        {isTeacher && (
          <button onClick={handleClick} className="flex items-center bg-purple-500 text-white my-4 p-2 rounded-lg hover:bg-purple-600">
            <User className="mr-2" />
            Admin
          </button>
        )}
      </div>

      <div className="mt-4 w-full md:w-[1280px] flex flex-col gap-6 items-center">
        {jobs.map((job: Job) => (
          <ClientJobCard
            key={job.id}
            job={job}
          />
        ))}
      </div>
      {loading && <div className="text-center mt-4">Loading more jobs...</div>}
      {!hasMore && !loading && <div className="text-center mt-4">No more jobs available</div>}
      {fetchFailed && !loading && (
        <div className="text-center mt-4 text-red-500">
          Failed to load jobs. Please check your network connection.<br></br>
          If the issue persists feel free to contact us 
        </div>
      )}
    </div>
  );
};

export default SearchJobs;
