import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { postInternshipApplication } from '../../../services/internship.service';

interface LocationState {
  id: string;
  role: string;
}

const InternshipApplyForm: React.FC = () => {
  const location = useLocation();
  const { id, role } = location.state as LocationState;

  const [formData, setFormData] = useState({
    id: id,
    name: '',
    email: '',
    phone: '',
    university: '',
    course: role,
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    university: ''
  });

  const [responseMessage, setResponseMessage] = useState<string | null>(null); // For success or error messages
  const [isError, setIsError] = useState<boolean>(false); // To differentiate between success and error

  const validateForm = () => {
    const newErrors = {
      name: '',
      email: '',
      phone: '',
      university: ''
    };
    let isValid = true;

    if (!formData.name) {
      newErrors.name = 'Name is required';
      isValid = false;
    }
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Valid email is required';
      isValid = false;
    }
    if (!formData.phone || !/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = 'Valid 10-digit phone number is required';
      isValid = false;
    }
    if (!formData.university) {
      newErrors.university = 'University/College name is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        await postInternshipApplication(formData);
        setResponseMessage('Offer letter has been sent to the respective email address!');
        setIsError(false); // Success
      } catch (error: any) {
        if (error.response && error.response.status === 500) {
          setResponseMessage('Error: Invalid email address or server issue.');
        } else {
          setResponseMessage('An unexpected error occurred.');
        }
        setIsError(true); // Error
      }
    }
  };

  return (
    <div className="max-w-lg mx-auto bg-white p-6 rounded-lg shadow-lg mt-20">
      <h1 className="text-2xl font-semibold text-center text-[#6941C6] mb-6">Internship Application Form</h1>

      {/* Conditional rendering for response messages */}
      {responseMessage && (
        <div className={`mb-4 p-4 rounded ${isError ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
          {responseMessage}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
            id="name"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            placeholder="Enter your full name"
          />
          {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            id="email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            placeholder="Enter your email"
          />
          {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
            Phone Number
          </label>
          <input
            id="phone"
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            placeholder="Enter your phone number"
          />
          {errors.phone && <p className="text-red-500 text-xs italic">{errors.phone}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="university">
            University/College
          </label>
          <input
            id="university"
            type="text"
            name="university"
            value={formData.university}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            placeholder="Enter your university/college name"
          />
          {errors.university && <p className="text-red-500 text-xs italic">{errors.university}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="course">
            Course Details
          </label>
          <input
            id="course"
            name="course"
            value={formData.course}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
            placeholder="Enter course details"
          />
        </div>

        <div className="flex items-center justify-center">
          <button
            type="submit"
            className="bg-[#7F56D9] hover:bg-[#6941C6] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit Application
          </button>
        </div>
      </form>
    </div>
  );
};

export default InternshipApplyForm;
