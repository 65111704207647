import { motion, useScroll, useTransform } from 'framer-motion';
import React from 'react';
import Image from './Image.webp';
import CRTHeroSlide from './CRTHeroSlide';
import Ripple from '../magicui/ripple';

const CRTHero = () => {
  const { scrollYProgress } = useScroll();

  // Transform values adjusted to avoid Y-axis movement before the scroll
  const headingY = useTransform(scrollYProgress, [0, 0.07], [0, -400]);
  const divX = useTransform(scrollYProgress, [0, 0.1], [800, 100]);
  const circleVisible = useTransform(scrollYProgress, [0, 0.05], [1, 0]);
  return (
    <div className=''>
      <div className="md:relative">
        {/* Hero section with sticky positioning */}
        <div className="h-[60vh] md:h-[100vh] sticky top-14 flex justify-center items-center overflow-hidden ">
          {/* Heading that moves upwards on scroll */}
          <motion.div
            className="text-2xl md:text-3xl text-headingBlack absolute  md:left-10  md:top-44 -translate-x-1/2 -translate-y-1/2 z-10 font-semibold w-full md:w-2/5 px-4 md:px-10 hidden md:block"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            style={ { y: headingY }} 
          >
            <h1>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, assumenda.</h1>
            <p className="text-lg md:text-xl text-paraGray font-medium my-3">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis, ipsam maiores! Quidem blanditiis aliquid fuga?
            </p>
          </motion.div>
          <div className='md:hidden top-16 absolute px-4'>
          <h1 className='text-2xl text-headingBlack font-medium'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Qui, assumenda.</h1>
            <p className="text-lg md:text-xl text-paraGray font-medium my-3">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis, ipsam maiores! Quidem blanditiis aliquid fuga?
            </p>
          </div>

          {/* Circle element adjusted for smaller screens */}
          <motion.div
            className="hidden md:flex shadow-xl rounded-full w-44 md:w-56 h-44 md:h-56 absolute right-10 md:right-[25vw] top-[30vh]  2xl:top-[40vh] xl:right-[28vw]  2xl:right-[36vw] overflow-hidden justify-center items-center z-40 border backdrop-blur-md"
            style={{ opacity: circleVisible }}
          >
            <h1 className="text-4xl md:text-5xl text-themepruple font-extrabold tracking-wider w-36 md:w-44 h-36 md:h-44 flex justify-center items-center rounded-full border shadow-md">
              CRT
            </h1>
          </motion.div>

          {/* Image that moves horizontally on scroll */}
          <motion.div
            initial={{ x: 800 }}
            style={{ x: divX }}
            className="absolute top-10 2xl:top-[25vh] -translate-y-1/2 -translate-x-1/2 shadow-sm z-20 hidden  md:block "
          >
        <img src={Image} alt="" className='shadow-md w-[80%] h-[60%]' />
          </motion.div>

          {/* Displayed image for small screens */}
          <img src={Image} alt="" className="absolute shadow-md bottom-12 md:hidden w-full px-4" />

          {/* Ripple effect displayed on larger screens */}
          <Ripple
            className="absolute -right-[28vw] 2xl:-right-[17vw] -top-9 z-10 hidden md:block "
            numCircles={3}
            mainCircleSize={250}
          />
        </div>

        <div className="md:h-[100vh]">{}</div>
      </div>
      <div className="w-screen h-fit py-4">
        <CRTHeroSlide />
      </div>
    </div>
  );
};

export default CRTHero;
