import React from 'react'
import quiz from './quiz.png'
import Reading from './Reading.png'
import Skill from './Skill.png'
import { Logos } from '../../data/Logos'
const CRTAddons = () => {
  return (
    <div className='py-10 lg:px-20 md:px-14 px-7'>
        <h1 className='text-headingBlack font-semibold text-2xl border-rose-50'>Additional Learning Enhancements</h1>
        <div className='grid grid-cols-1 lg:grid-cols-2 my-5 gap-5 ' >
            <div className=''>
                <img src={quiz} alt="quiz banner" className='mb-3 rounded-md'/>
                <span className='px-2 py-2 border rounded-full bg-[#f9f5ff] text-themepruple text-sm my-5'> 
                    <span className='px-2 py-1 border rounded-full bg-white mr-1'>
                        Test your skills</span>
                    8 min read
                </span>
                <h1 className='text-themepruple font-semibold text-sm mt-6'>Olivia Rhye • 20 Jan 2024</h1>
                <div className='flex justify-between'>
                    <h4 className='font-semibold text-headingBlack text-2xl mt-3'>Quiz</h4>
                    <Logos.ArrowUpRight/>
                </div>
                <p className='text-paraGray font-normal text-lg mt-3'>How do you create compelling presentations that wow your colleagues and impress your managers?</p>
                <div className='flex gap-3 my-3'>
                    <span className='text-themepruple px-3 py-1 rounded-full border border-[#E9D7FE] bg-[#F9F5FF] '>Design</span>
                    <span className='text-[#3538CD] px-3 py-1 rounded-full border border-[#C7D7FE] bg-[#EEF4FF] '>Research</span>
                    <span className='text-[#C11574] px-3 py-1 rounded-full border border-[#FCCEEE] bg-[#FDF2FA] '>Presentation</span>
                </div>
            </div>
            <div className='flex flex-col gap-7'>
                <div className='flex flex-col md:flex-row gap-4'>
                    <img src={Reading} alt="Reading Materials" className='h-48' />
                    <div>
                    <span className='px-2 py-2 border rounded-full bg-[#f9f5ff] text-themepruple text-sm my-5'> 
                    <span className='px-2 py-1 border rounded-full bg-white mr-1'>
                        Learn</span>
                    4 min read
                    </span>
                    <h1 className='text-headingBlack font-semibold text-lg my-2'>Reading Materials</h1>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum debitis corrupti impedit
                    </p>
                    <div className='flex gap-3 my-3'>
                    <span className='text-themepruple px-3 py-1 rounded-full border border-[#E9D7FE] bg-[#F9F5FF] '>Design</span>
                    <span className='text-[#3538CD] px-3 py-1 rounded-full border border-[#C7D7FE] bg-[#EEF4FF] '>Research</span>
                    
                </div>
                    </div>
                </div>
                
                <div className='flex flex-col md:flex-row gap-4'>
                    <img src={Skill} alt="Skill " className='h-48'/>
                    <div>
                    <h1 className='text-themepruple font-semibold text-sm'>Olivia Rhye • 20 Jan 2024</h1>

                    <h1 className='text-headingBlack font-semibold text-lg my-2'>Building your API stack</h1>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum debitis corrupti impedit
                    </p>
                    <div className='flex gap-3 my-3'>
                    <span className='text-themepruple px-3 py-1 rounded-full border border-[#E9D7FE] bg-[#F9F5FF] '>Software</span>
                    <span className='text-[#C11574] px-3 py-1 rounded-full border border-[#FCCEEE] bg-[#FDF2FA] '>Research</span>
                    
                </div>
                    </div>
                </div>
                
            </div>

        </div>

    </div>
  )
}


export default CRTAddons